import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";

const ls = new SecureLS({ isCompression: true, encodingType: 'rc4', encryptionSecret: process.env.VUE_APP_ENC_KEY });

Vue.use(Vuex);

export default new Vuex.Store({

    state: {
        user: {},
        global: {},
    },


    mutations: {
        setUser(state, items) {
            state.user = Object.assign(state.user, items)
        },

        setGlobal(state, items) {
            state.global = Object.assign(state.global, items)
        },

        logout(state) {
            state.user = {}
            state.global = {}
        }
    },


    getters: {
        user: state => state.user,
        global: state => state.global,
    },


    actions: {
        logout({ commit }) {
            commit('logout')
            window.localStorage.clear();
			window.sessionStorage.clear();
			document.cookie.split(";").forEach(function (c) {
				document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
			});
			window.location.reload();
        },

        login({ commit }, payload) {
            commit('setUser', payload)
            location.reload()
        }
    },


    modules: {},


    plugins: [
        createPersistedState({
            storage: {
                getItem: key => ls.get(key),
                setItem: (key, value) => ls.set(key, value),
                removeItem: key => ls.remove(key)
            }
        })
    ],
});
