export default {
    methods: {
        popToast(title, text, variant) {
            this.$bvToast.toast(text, {
                solid: true,
				title: title,
                variant: variant || 'success',
                autoHideDelay: 5000,
                toaster: "b-toaster-top-right",
            });
        }
    }
}