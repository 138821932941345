export default {

    methods: {

        download(url, name) {

            fetch(url).then(function(t) {

                return t.blob().then((b) => {

                    var a = document.createElement("a");
                    a.href = URL.createObjectURL(b);
                    a.setAttribute("download", name);
                    a.click();

                });

            });

        }

    }
    
}