<template>
  <div id="app">
    <div v-if="checkUser.warehouse">
      <HeaderWarehouse />
    </div>
    <div v-if="checkUser.warehouseHub">
      <HeaderWarehouseHub />
    </div>
    <div v-if="checkUser.warehouseImports">
      <HeaderWarehouseImports />
    </div>
    <div class="p-4">
      <router-view />
    </div>
  </div>
</template>

<script>
import HeaderWarehouse from "@/components/layout/HeaderWarehouse.vue";
import HeaderWarehouseHub from "@/components/layout/HeaderWarehouseHub.vue";
import HeaderWarehouseImports from "@/components/layout/HeaderWarehouseImports.vue";
import warehouseRoutes from "./router/warehouseRoutes";
import warehouseHubRoutes from "./router/warehouseHubRoutes";
import warehouseImportRoutes from "./router/warehouseImportRoutes";
import bottomRoutes from "./router/bottomRoutes";

export default {
  name: "App",
  components: {
    HeaderWarehouse,
    HeaderWarehouseHub,
    HeaderWarehouseImports
  },
  computed: {
    checkUser() {
      const user = this.$store.getters.user;
      const data = { ...user };
      if (user && user.id && user.role == 5) {
        data["warehouse"] = true;
      } else if (user && user.id && user.role == 45) {
        data["warehouseHub"] = true;
      } else if (user && user.id && user.role == 56) {
        data["warehouseImports"] = true;
      } else {
        data["login"] = true;
      }
      return data;
    }
  },
  mounted() {
    if (this.checkUser.email) {
      document.body.classList.add('bg-white')
    }
    if (this.checkUser.locked) {
      document.body.classList.remove('bg-white')
    }
    if (this.checkUser.warehouse) {
      [...warehouseRoutes, ...bottomRoutes].forEach((el) => this.$router.addRoute(el));
    } else if (this.checkUser.warehouseHub) {
      [...warehouseHubRoutes, ...bottomRoutes].forEach((el) => this.$router.addRoute(el));
    } else if (this.checkUser.warehouseImports) {
      [...warehouseImportRoutes, ...bottomRoutes].forEach((el) => this.$router.addRoute(el));
    } else {
      this.$router.push('/login');
    }
  },
}
</script>
<style>
.form-input>label {
  border-color: #ced4da !important;
}

.custom-file-label {
  border: 1px solid #ced4da !important;
}

.row-danger {
    background-color: #ff00002e !important;
}

</style>