const warehouseRoutes = [
    {
        path: "/inscan",
        name: "InScan",
        component: () => import("../components/InScan.vue"),
        meta: { transitionName: "slide" },
    },
    {
        path: "/bagging",
        name: "Bagging",
        component: () => import("../components/Bagging.vue"),
        meta: { transitionName: "slide" },
    },
    {
        path: "/outscan",
        name: "OutScan",
        component: () => import("../components/OutScan.vue"),
        meta: { transitionName: "slide" },
    },
    {
        path: "/inventory",
        name: "Inventory",
        component: () => import("../components/Inventory.vue"),
        meta: { transitionName: "slide" },
    },
    {
        path: "/debag",
        name: "Debag",
        component: () => import("../components/Debag.vue"),
        meta: { transitionName: "slide" },
    },
    {
        path: "/deletebag",
        name: "DeleteBag",
        component: () => import("../components/DeleteBag.vue"),
        meta: { transitionName: "slide" },
    },
    {
        path: "/deletelmawb",
        name: "DeleteLmAWB",
        component: () => import("../components/DeleteLmAWB.vue"),
        meta: { transitionName: "slide" },
    },
    {
        path: "/manual-inscan",
        name: "ManualInscan",
        component: () => import("../components/ManualInscan.vue"),
        meta: { transitionName: "slide" },
    },
    {
        path: "/print-label",
        name: "PrintLabel",
        component: () => import("../components/PrintLabel.vue"),
        meta: { transitionName: "slide" },
    },
    {
        path: "/dhl-awb",
        name: "DHLAWB",
        component: () => import("../components/DHLAWB.vue"),
        meta: { transitionName: "slide" },
    },
    {
        path: "/mawboutliners",
        name: "MAWB-AWB Outliers",
        component: () => import("../components/mawboutliers.vue"),
        meta: { transitionName: "slide" },
    },
    {
        path: "/barcode-generator",
        name: "BarcodeGenerator",
        component: () => import("../components/BarcodeGenerator.vue"),
        meta: { transitionName: "slide" },
    },
    {
        path: "/prescan",
        name: "PreScan",
        component: () => import("../components/PreScan.vue"),
        meta: { transitionName: "slide" },
    },
    {
        path: "/xray-images",
        name: "XRayImages",
        component: () => import("../components/XRayImages.vue"),
        meta: { transitionName: "slide" },
    },
    {
        path: "/import-destination-scan",
        name: "ImportDestinationScan",
        component: () => import("../components/ImportDestinationScan.vue"),
        meta: { transitionName: "slide" },
    },
    {
        path: "/rto",
        name: "MarkRTO",
        component: () => import("@/components/markrto.vue"),
        meta: { transitionName: "slide" },
    },
    {
        path: "/cargo-scan",
        name: "CargoScan",
        component: () => import("@/components/CargoScan.vue"),
        meta: { transitionName: "slide" },
    },
];

export default warehouseRoutes;
