export default function date (value) {
    const date = new Date(value)
    var options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit"
    }
    return date.toLocaleDateString(['es-CL'],options)
    // const date = new Date(value)
    // return `${date.getFullYear()}-${date.getMonth()}-${date.getDay()}`;
}

// export default function dateFormat (value) {
//     const date = new Date(value)
//     return `${date.getFullYear()}-${data.getMonth()}-${date.getDay()}`;
// }