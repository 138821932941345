export default function initials (name) {
    let words = name.split(" ")
    if(name.length>0) {

        let short_hand = words[0][0]
        if(words[words.length-1][0]) {
            short_hand += words[words.length-1][0]
        }else{
            short_hand += words[0][1]
        }

        return short_hand.toUpperCase()
    }   
}