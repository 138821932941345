import Vue from "vue";
import App from "./App.vue";
import axios from "axios";
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import { ButtonGroupPlugin } from "bootstrap-vue";

import "./assets/scss/app.scss";
import "vue-multiselect/dist/vue-multiselect.min.css";
import router from "./router";
import store from "./store";
import title from "./mixins/title";
import toaster from "./mixins/toaster";
import download from "./mixins/download";
import filters from "./filters/index";


Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(ButtonGroupPlugin);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const { status } = error.response;
    if (status === 401) {
      store.commit("setUser", { locked: true });
    }
    return Promise.reject(error);
  }
);

const timestamp = +new Date();
const appID = 2;

const headers = {
  "x-sellerid": 2,
  "x-appid": appID,
  Authorization: `Bearer ${store.getters.user.token}`,
  "x-timestamp": timestamp,
  "Content-Type": "application/json",
};

axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT;
axios.defaults.headers = headers;
Vue.prototype.axios = axios;

Vue.config.productionTip = false;

Vue.mixin(title);
Vue.mixin(toaster);
Vue.mixin(download);
Vue.use(filters);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
