const topRoutes = [
    {
        path: "/",
        redirect: "/login",
    },
    {
        path: "/login",
        name: "Login",
        component: () => import("../components/Login.vue"),
        meta: { transitionName: "slide" },
    },
];

export default topRoutes;
