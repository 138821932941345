const bottomRoutes = [
    {
        path: "**",
        name: "PageNotFound",
        component: () => import("../components/PageNotFound.vue"),
        meta: { transitionName: "slide" },
    },
];

export default bottomRoutes;
