const warehouseHubRoutes = [
    {
        path: "/inscan-hub",
        name: "InScanHub",
        component: () => import("../components/InScanHub.vue"),
        meta: { transitionName: "slide" },
    },
];

export default warehouseHubRoutes;
