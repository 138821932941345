const warehouseImportRoutes = [
    {
        path: "/import-origin-scan",
        name: "ImportOriginScan",
        component: () => import("../components/imports/ImportOriginScan.vue"),
        meta: { transitionName: "slide" },
    },
];

export default warehouseImportRoutes;
