<template>
	<div class="header bg-primary text-white">
		<div class="main">
			<ul class="nav navbar p-0">
				<li :class="{ 'active': tab == 'ImportOriginScan' ? true : false }">
					<b-link href="/import-origin-scan" class="nav-link">
						<span class="mr-1 small">
							<b-icon-upc-scan></b-icon-upc-scan>
						</span> Scan Barcode
					</b-link>
				</li>
			</ul>
		</div>
		<div class="slave active">
			<b-nav-item-dropdown class="nav navbar p-1" right>
				<li>
					<b-link href="javascript:void(0)" @click="$store.dispatch('logout', {});" class="nav-link">
						<span class="mr-1 small">
							<b-icon-power></b-icon-power>
						</span> Logout
					</b-link>
				</li>
			</b-nav-item-dropdown>
		</div>
	</div>
</template>

<script>
export default {
	name: "HeaderWarehouseImports",
	computed: {
		tab() {
			return this.$route.name;
		}
	}
}
</script>

<style lang="scss">
.header {
	display: flex;
	border: 4px solid #405479;
	border-bottom: none;

	>.main {
		width: calc(100% - 35px);

		>ul {
			li {
				margin: auto;
				font-weight: bold;
				font-size: 21px;
				width: 25%;
				text-align: center;
			}

			li.active a {
				color: #405479;
				background: #f5f5f5;
				border-top-left-radius: 5px;
				border-top-right-radius: 5px;
			}

			a {
				display: block;
				color: #fff;
				text-decoration: none;
			}
		}
	}

	>.slave {
		margin-left: 4px;

		&.active {
			background: #fff;

			a {
				color: #405479;
			}
		}
	}
}

a.nav-link {
	padding: 1rem;
}

.dropdown-toggle {
	color: #fff;
}

.dropdown-menu li {
	width: 200px;
}

.logout {
	position: fixed;
	bottom: 10px;
	left: 10px;
	cursor: pointer;
	z-index: 11;
}
</style>