


export default function hourtime(UNIX_timestamp){
  var a = new Date(UNIX_timestamp).toLocaleTimeString()
  // var date = a.getDate();
  // var hour = a.getHours();
  // var min = a.getMinutes();
  // var sec = a.getSeconds();
  // var time = hour + ':' + min + ':' + sec ;
  return a;
}