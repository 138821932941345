<template>
	<div class="header bg-primary text-white">
		<div class="main">
			<ul class="nav navbar p-0">
				<li :class="{ 'active': tab == 'InScan' ? true : false }">
					<b-link href="/inscan" class="nav-link">
						<span class="mr-1 small">
							<b-icon-box-arrow-in-down-right></b-icon-box-arrow-in-down-right>
						</span> InScan
					</b-link>
				</li>
				<li :class="{ 'active': tab == 'Bagging' ? true : false }">
					<b-link href="/bagging" class="nav-link">
						<span class="mr-1 small">
						</span> Bagging
					</b-link>
				</li>
				<li :class="{ 'active': tab == 'OutScan' ? true : false }">
					<b-link href="/outscan" class="nav-link">
						<span class="mr-1 small">
							<b-icon-box-arrow-in-up-right></b-icon-box-arrow-in-up-right>
						</span> OutScan
					</b-link>
				</li>
				<li :class="{ 'active': tab == 'Inventory' ? true : false }">
					<b-link href="/inventory" class="nav-link">
						<span class="mr-1 small">
							<b-icon-card-checklist></b-icon-card-checklist>
						</span> Inventory
					</b-link>
				</li>
			</ul>
		</div>
		<div class="slave"
			:class="{ 'active': ['Debag', 'DeleteBag', 'DeleteLmAWB', 'ManualInscan', 'PrintLabel', 'DHLAWB', 'PreScan', 'BarcodeGenerator', 'XRayImages', 'ImportDestinationScan', 'MarkRTO'].includes(tab) }">
			<b-nav-item-dropdown class="nav navbar p-1" right>
				<li>
					<b-link href="/debag" class="nav-link">
						<span class="mr-1 small">
							<b-icon-bag-x></b-icon-bag-x>
						</span> Debag
					</b-link>
				</li>
				<li>
					<b-link href="/deletebag" class="nav-link">
						<span class="mr-1 small">
							<b-icon-trash></b-icon-trash>
						</span> Delete Bag
					</b-link>
				</li>
				<li>
					<b-link href="/deletelmawb" class="nav-link">
						<span class="mr-1 small">
							<b-icon-trash></b-icon-trash>
						</span> Delete LM-AWB
					</b-link>
				</li>
<!-- 				<li>
					<b-link href="/manual-inscan" class="nav-link">
						<span class="mr-1 small">
							<b-icon-keyboard></b-icon-keyboard>
						</span> Manual Inscan
					</b-link>
				</li> -->
				<li>
					<b-link href="/print-label" class="nav-link">
						<span class="mr-1 small">
							<b-icon-printer></b-icon-printer>
						</span> Print Label
					</b-link>
				</li>
				<li>
					<b-link href="/dhl-awb" class="nav-link">
						<span class="mr-1 small">
							<b-icon-search></b-icon-search>
						</span> DHL-AWB
					</b-link>
				</li>

				<li>
					<b-link href="/mawboutliners" class="nav-link">
						<span class="mr-1 small">
							<b-icon-bag-dash></b-icon-bag-dash>
						</span> MAWB-AWB Outliers
					</b-link>
				</li>

				<li>
					<b-link href="/barcode-generator" class="nav-link">
						<span class="mr-1 small">
							<b-icon-upc-scan></b-icon-upc-scan>
						</span> Barcode Generator
					</b-link>
				</li>

				<li>
					<b-link href="/xray-images" class="nav-link">
						<span class="mr-1 small">
							<b-icon-upload></b-icon-upload>
						</span>Xray Images
					</b-link>
				</li>

				<li>
					<b-link href="/prescan" class="nav-link">
						<span class="mr-1 small">
							<b-icon-box-arrow-in-down-right></b-icon-box-arrow-in-down-right>
						</span> PreScan
					</b-link>
				</li>

				<li>
					<b-link href="/import-destination-scan" class="nav-link">
						<span class="mr-1 small">
							<b-icon-upc-scan></b-icon-upc-scan>
						</span> Import InScan
					</b-link>
				</li>

				<li>
					<b-link href="/rto" class="nav-link">
						<span class="mr-1 small">
							<img src="@/assets/rto.png" width="20">
						</span> RTO Label
					</b-link>
				</li>

				<li>
					<b-link href="/cargo-scan" class="nav-link">
						<span class="mr-1 small">
							<b-icon-keyboard></b-icon-keyboard>
						</span> Manual Inscan
					</b-link>
				</li>

				<b-dropdown-divider></b-dropdown-divider>
				<li>
					<b-link href="javascript:void(0)" @click="$store.dispatch('logout', {});" class="nav-link">
						<span class="mr-1 small">
							<b-icon-power></b-icon-power>
						</span> Logout
					</b-link>
				</li>
				<li>
					<b-link @click="$store.commit('setUser', { 'locked': true }); $router.go($router.currentRoute)"
						class="nav-link">
						<span class="mr-1 small">
							<b-icon-shield-lock></b-icon-shield-lock>
						</span> Lock Panel
					</b-link>
				</li>
			</b-nav-item-dropdown>
		</div>
		<div class="logout btn btn-warning btn-sm"
			@click="$store.commit('setUser', { 'locked': true }); $router.go($router.currentRoute)"
			title="Lock warehouse panel" v-b-tooltip.hover.right>Lock <b-icon-shield-lock></b-icon-shield-lock></div>
	</div>
</template>

<script>
export default {
	name: "HeaderWarehouse",
	computed: {
		tab() {
			return this.$route.name;
		}
	}
}
</script>

<style lang="scss">
.header {
	display: flex;
	border: 4px solid #405479;
	border-bottom: none;

	>.main {
		width: calc(100% - 35px);

		>ul {
			li {
				margin: auto;
				font-weight: bold;
				font-size: 21px;
				width: 25%;
				text-align: center;
			}

			li.active a {
				color: #405479;
				background: #f5f5f5;
				border-top-left-radius: 5px;
				border-top-right-radius: 5px;
			}

			a {
				display: block;
				color: #fff;
				text-decoration: none;
			}
		}
	}

	>.slave {
		margin-left: 4px;

		&.active {
			background: #fff;

			a {
				color: #405479;
			}
		}
	}
}

a.nav-link {
	padding: 1rem;
}

.dropdown-toggle {
	color: #fff;
}

.dropdown-menu li {
	width: 200px;
}

.logout {
	position: fixed;
	bottom: 10px;
	left: 10px;
	cursor: pointer;
	z-index: 11;
}
</style>