export default function currency(n, type) {
    if (!n)
        return '0'
    let d = 0
    if(Number(n) === n && n % 1 !== 0) {
        d = 2
    }
    return n.toLocaleString('en-IN', {
        maximumFractionDigits: d,
        style: 'currency',
        currency: type
    })
}